@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --standard-width: 608px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: fade-in 1.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-datetime-edit-day-field[disabled] {
  color: gray;
}

#loading-page {
  animation: 1s linear 1s infinite alternate slidein;
}

@keyframes slidein {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: rgb(142, 238, 246);
}
